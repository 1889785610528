<template>
<div>
  <!-----------Coupen List Start -------------------->
  <a href="javascript:void(0)" @click="clickOpenModal(promo)">
    <div v-if="!right" class="coupen-list">
      <div class="busines-img">
        <img :src="promo.coupon_image" class="coupon-img">
        <div class="resturant-data ml-3">
            <h4>{{promo.category_name}}</h4>
            <span>{{promo.category_desc}}</span>
        </div>
      </div>
    </div>
  </a>
  <!------------Coupen List End -------------------->
</div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  components: {
    'skeleton-loader-vue':VueSkeletonLoader
  },
  props: ['promo','right','businessCategoryLayout'],
  data() {
    return {
      isCoupenModalList: false,
    }
  },
  mounted() {
    this.businessSlider();
  },
  
  methods: {
    businessSlider(){
      window.$("#coupen-slider").owlCarousel({
      items : 9,
      itemsDesktop: [1199,9],
      itemsDesktopSmall: [979,4],
      itemsTablet: [768,4],
      itemsMobile:[479,2],
      pagination: true,
      navigation: true,
      navigationText: ["<", ">"],
      autoPlay: false,
      pagination: false,
      });
    },
    
    clickOpenModal(data) {
      this.$emit("coupenModal", data);
    }
}

};
</script>
<style scoped>
img.coupon-img {height: 100px !important;object-fit: contain !important;}
</style>