<template>
  <div class="homslider-cls">
  <div class="hm-slider-responsive" v-if="is_scrren_hide" >
    <home-nav-bar v-on:isDelivery="isHomeDeliverySet" v-on:getLatLong="onMobileLocationClick"></home-nav-bar>
  </div>
    <div class="home-slider-main" v-if="banners && banners.length > 0">
      <div id="testimonial-slider" class="owl-carousel">
        <div v-for="(banner,index) in banners" :key="index">
          <router-link  :to="{name:'banner-items', params:{banner_id: banner.id}}" v-if="banner.type =='restaurant' && banner.banner_restaurant.length > 1 && $auth.setting && (($auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item > 1) || (($auth.setting.product_type.id == 1 && $auth.setting.business_category_item <= 1) || ($auth.setting.product_type.id == 2 && $auth.setting.store_selection == 1)))">
            <div class="testimonial-item equal-height style-6">
              <img :src="banner.image" />
            </div>
          </router-link>
          <router-link v-if="banner.type =='restaurant' && banner.banner_restaurant.length == 1 && $auth.setting && (($auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item > 1) || (($auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item <= 1) || ($auth.setting.product_type && $auth.setting.product_type.id == 2 && $auth.setting.store_selection == 1)))" :to="{name:'vendor', params:{restaurant_name: banner.banner_restaurant[0].slug}}">
            <div class="testimonial-item equal-height style-6">
              <img :src="banner.image" />
            </div>
          </router-link>
          <a :href="(banner.link.includes('http')?banner.link:'//'+banner.link)" target="_blank" v-if="banner.type =='link' && banner.banner_restaurant.length == 0 && banner.menu_category_id == '0'" >
            <div class="testimonial-item equal-height style-6">
              <img :src="banner.image" />
            </div>
          </a>
          <div v-if="banner.type =='noaction' && banner.banner_restaurant.length == 0" class="testimonial-item equal-height style-6">
              <img :src="banner.image" />
          </div>
          <!---- Single vendor for start-------------->
           <!-- <router-link v-if="banner.banner_restaurant.length == 1 && $auth.setting && ($auth.setting.product_type && $auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0) && banner.menu_category_id == '0'" :to="{name:'vendor', params:{restaurant_name: banner.banner_restaurant[0].slug}}">
            <div class="testimonial-item equal-height style-6">
              <img :src="banner.image" />
            </div>
          </router-link> -->
          <a href="javascript:void(0);" v-if="banner.type =='restaurant' && $auth.setting && $auth.setting.product_type && ($auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0) && banner.menu_category_id == '0'" >
            <div class="testimonial-item equal-height style-6">
              <img :src="banner.image" />
            </div>
          </a>
          <a href="javascript:void(0);" v-if="$auth.setting && $auth.setting.product_type && ($auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0) && banner.menu_category_id != '0'" @click="getBannerCategoryId(banner.menu_category_id)" >
            <div class="testimonial-item equal-height style-6">
              <img :src="banner.image" />
            </div>
          </a>
          <!---- Single vendor for start -------------->
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import HomeNavBar from "@/components/HomeNavBar.vue";

export default {
  props:['latitude','longitude',"is_pickup_delivery"],
  components: {HomeNavBar},
  data() {
    return {
      loading: true,
      banners:[],
      is_scrren_hide:(window.screen.width < 1024 ? true:false)
    };
  },

  mounted(){
    if(this.latitude){
      this.getBanner().then(() => {
        window.$("#testimonial-slider").owlCarousel({
          items: 3,
          itemsDesktop: [1000, 2],
          itemsDesktopSmall: [980, 1],
          itemsTablet: [768, 1],
          pagination: true,
          navigation: true,
          navigationText: ["<", ">"],
          autoPlay: (this.$auth.setting.auto_scroll_banner=='1'?true:false),
          pagination: false,
        });
      });
    }
  },

  watch:{
    latitude:function(){
      setTimeout(() =>{
        if(this.getBanner()){
          this.getBanner().then(() => {
          window.$("#testimonial-slider").owlCarousel({
            items: 3,
            itemsDesktop: [1000, 2],
            itemsDesktopSmall: [980, 1],
            itemsTablet: [768, 1],
            pagination: true,
            navigation: true,
            navigationText: ["<", ">"],
            autoPlay: (this.$auth.setting.auto_scroll_banner=='1'?true:false),
            pagination: false,
          });
        });
        }        
      },2000)
      
    }
  },

  methods: {
    ...mapActions("restaurant",["getBanners","getBannerCategoryData"]),
    
    isHomeDeliverySet(value){
      this.$emit("isDeliveryHome", value);
      this.$store.commit("order/pickupdelivery",value);
    },
    onMobileLocationClick(value){
      this.$emit("getMobileLatLong", value);
    },

    getBanner(latitude,longitude) {
      latitude = this.latitude;
      longitude = this.longitude;
      let isGetRestaurant = true;
      this.loading = true;
      if(latitude) {
        //console.log(this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0);
        if(this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0){
          return this.getBannerCategoryData({
            vendor_id: this.$auth.getVendorId(),
            latitude: latitude,
            longitude: longitude,
            delivery_type_time_slots : this.is_pickup_delivery.toString(),
            is_langauge: this.$store.state.lang.locale            
          }).then((data)=>{
            if(data.code == 200){
              this.banners = data.Result;
              isGetRestaurant = true;
              this.$emit("getRestaurantData",isGetRestaurant);
            }else if(data.code == 101){
              isGetRestaurant = false;
              this.banners=[];
              this.loading = false;
              this.$emit("getRestaurantData",isGetRestaurant);
            }else {
              this.banners=[];
              this.loading = false;
            }
          })
        }else if(this.$auth.setting && (this.$auth.setting.product_type && this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item > 1) || ((this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item <= 1) || (this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 1))) {
          return this.getBanners({
            vendor_id: this.$auth.getVendorId(),
            latitude: latitude,
            longitude: longitude,
            delivery_type_time_slots : this.is_pickup_delivery
          }).then((data)=>{
            if(data.code == 200){
              this.banners = data.Result;
              isGetRestaurant = true;
              this.$emit("getRestaurantData",isGetRestaurant);
            }else if(data.code == 101){
              isGetRestaurant = false;
              this.banners=[];
              this.loading = false;
              this.$emit("getRestaurantData",isGetRestaurant);
            }else {
              this.banners=[];
              this.loading = false;
            }
          })
        }
      }
      
    },

    getBannerCategoryId(id){
      this.$emit("setSingleRestaurantId", id);
    }
  }
};
</script>